import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import LoginForm from './components/LoginForm';
import Dashboard from './pages/Dashboard';
import Pedidos from './pages/Pedidos';
import PedidosHistoricos from './pages/PedidosHistoricos';

function App() {
  console.log("Renderizando App...");

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/welcome/*" element={<Layout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="pedidos" element={<Pedidos />} />
          <Route path="pedidos-historicos" element={<PedidosHistoricos />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

// src/components/Layout.jsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import '../styles/Layout.css';

function Layout() {
    return (
        <div className="layout collapsed">
            <Sidebar />
            <div className="main-content">
                <Outlet /> {/* Aquí es donde se renderiza el contenido */}
            </div>
        </div>
    );
}

export default Layout;

// src/components/Sidebar.jsx
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../styles/Sidebar.css';


function Sidebar() {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('authToken'); 
        alert('Sesión cerrada correctamente.');
        navigate('/');  // Redirige al login
    };

    return (
        <div className="sidebar">
            <h2>Menú</h2>
            <ul>
                <li>
                    <NavLink to="/welcome/dashboard" className={({ isActive }) => (isActive ? 'active' : '')}>
                        Dashboard
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/welcome/pedidos" className={({ isActive }) => (isActive ? 'active' : '')}>
                        Pedidos
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/welcome/pedidos-historicos" className={({ isActive }) => (isActive ? 'active' : '')}>
                        Pedidos Históricos
                    </NavLink>
                </li>
            </ul>
            <button className="logout-btn" onClick={handleLogout}>Cerrar Sesión</button>
        </div>
    );
}

export default Sidebar;
